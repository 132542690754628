import React from "react";
import PropTypes from "prop-types";
import { PageTypes } from "@sparefoot/segment-react";
import { getErrorViewPixel } from "@sparefoot/react-commons/utils/tracking";
import { Status } from "utils/routes";
import { Page, Container } from "components/core/Page";
import { H1, Paragraph, Text, Link } from "components/core/Typography";
import { SearchBar } from "components/search/SearchBar";
import ErrorRobot from "assets/images/error-robot.svg";

import "./ErrorPage.scss";

export default function ErrorPage({ message, statusCode, redirectURL }) {
	return (
		<Status
			code={statusCode}
			redirectURL={redirectURL}
		>
			<Page
				verticalSpacing
				className="error-page"
				trackingPixel={getErrorViewPixel(`error${statusCode}`)}
				pageType={PageTypes.ERROR}
				title={
					statusCode === 404
						? "404 Page Not Found - Storage.com"
						: `${statusCode} Error - Storage.com`
				}
			>
				<Container>
					<div className="content">
						<div className="messaging text">
							<div className="text-content-container">
								{statusCode === 404 && (
									<Text
										weight="bold"
										color="red"
									>
										Error Code: 404
									</Text>
								)}
								<H1 mediumSize="large">
									{statusCode === 404
										? "Sorry, the page you are looking for was not found."
										: "Something went wrong!"}
								</H1>
								<Paragraph
									color="gray"
									last
								>
									Looking for self storage? Start a self
									storage search below:
								</Paragraph>
							</div>
							<SearchBar
								buttonText="Find Storage"
								id="error-page-search-bar"
								label="Enter ZIP code..."
								theme="lightBlue3"
								segmentCategory="ErrorPage-SearchBar"
							/>
							<span className="call-us-container">
								<Text>Or give us a call at: </Text>
								<Link
									href="tel:833-448-1067"
									weight="bold"
									color="blue"
								>
									833-448-1067
								</Link>
							</span>
						</div>
						<div className="messaging visual">
							<img
								className="error-image"
								src={ErrorRobot}
								alt="sad broken robot"
							/>
						</div>
					</div>
				</Container>

				{/* If this is dev, show the stack trace */}
				{process.env.NODE_ENV === "development" && (
					<Container>
						<div className="stack-trace">
							<pre>{message}</pre>
						</div>
					</Container>
				)}
			</Page>
		</Status>
	);
}

ErrorPage.defaultProps = {
	statusCode: 404
};

ErrorPage.propTypes = {
	message: PropTypes.string,
	statusCode: PropTypes.number,
	redirectURL: PropTypes.string
};
