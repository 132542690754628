import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

export default function Status({ code, redirectURL, children }) {
	return (
		<Route
			render={({ staticContext }) => {
				if (staticContext) {
					staticContext.status = code;
				}
				if (staticContext && redirectURL) {
					staticContext.redirectURL = redirectURL;
				}
				return children;
			}}
		/>
	);
}

Status.propTypes = {
	code: PropTypes.number.isRequired,
	redirectURL: PropTypes.string,
	children: PropTypes.node
};
